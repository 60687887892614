import {
  AddressInput,
  InitializeRequisitionInput,
  Item,
  Maybe,
  RequisitionPurchase,
  RequisitionScheduleInput
} from '@bloom-coffee/espresso'
import { Button, Color, Spinner, Text } from '@bloom-coffee/steamed-milk'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useInitializeRequisitionMutation, usePriceTagQuery } from '../../graphql/types.generated'
import { logger } from '../../logger'
import { PurchaseSummary } from './PurchaseSummary'

interface CheckoutContainerProps {
  first: string
  last: string
  item: Item
  shippingAddress: AddressInput
  onCancel: () => void
}

export const CheckoutContainer = (props: CheckoutContainerProps) => {
  const { item, first, last, shippingAddress, onCancel } = props

  const [error, setError] = useState<string | undefined>()
  const [confirmingPaymentIntent, setConfirmingPaymentIntent] = useState(false)

  const { loading: loadingPriceTag, data: priceTagData } = usePriceTagQuery({
    variables: {
      input: {
        itemIds: [item.id],
        shippingAddress
      }
    }
  })

  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()

  const [execute] = useInitializeRequisitionMutation()

  async function confirmCardPayment() {
    if (confirmingPaymentIntent) {
      return
    }

    if (!stripe || !elements) {
      setError('Something went wrong')
      return
    }

    setError(undefined)
    setConfirmingPaymentIntent(true)

    const cardElement = elements.getElement(CardElement)!
    const { error: cardError, paymentMethod } = await stripe!.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if (cardError) {
      setError(cardError.message)
      setConfirmingPaymentIntent(false)
    } else {
      let schedule: Maybe<RequisitionScheduleInput> = null
      if (!!item?.scheduleFrequency) {
        schedule = {
          frequency: item.scheduleFrequency,
          paused: false,
          archived: false
        }
      }

      const input: InitializeRequisitionInput = {
        itemIds: [item!!.id],
        fulfillment: {
          paymentMethodId: paymentMethod.id,
          schedule,
          shippingAddress: shippingAddress || null,
          firstName: first,
          lastName: last
        }
      }

      let purchase: Maybe<RequisitionPurchase>
      try {
        const res = await execute({ variables: { input } })
        const resultData = res.data?.initializeRequisition
        purchase = resultData
      } catch (e) {
        logger.error('CheckoutContainer', `${e}`)
      }

      if (!purchase) {
        setError('Something went wrong')
        setConfirmingPaymentIntent(false)
      } else {
        if (!!purchase.paymentIntent) {
          const clientSecret = purchase.paymentIntent.stripeClientSecret!!
          const { paymentIntent, error: confirmError } = await stripe!.confirmCardPayment(
            clientSecret,
            { payment_method: paymentMethod.id },
            { handleActions: false }
          )

          if (confirmError) {
            setError(`${confirmError.message}`)
            setConfirmingPaymentIntent(false)
          } else {
            if (paymentIntent.status === 'requires_action') {
              const { error: confirmError2 } = await stripe!.confirmCardPayment(clientSecret)
              if (confirmError2) {
                setError(confirmError2.message)
                setConfirmingPaymentIntent(false)
              } else {
                navigate('/beans/account')
              }
            } else {
              navigate('/beans/account')
            }
          }
        } else {
          navigate('/beans/account')
        }
      }
    }
  }

  const textFieldWidth = window.innerWidth > 400 ? 250 : 225

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 12 }}>
          <div
            style={{
              border: `1px solid ${Color.RDY_FOREST}`,
              padding: '12px 8px 12px 8px',
              width: textFieldWidth
            }}
          >
            <CardElement />
          </div>
        </div>

        {error && (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 8, paddingBottom: 8 }}>
            <Text variant='error'>{error}</Text>
          </div>
        )}

        {loadingPriceTag || confirmingPaymentIntent ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginBottom: 8 }}>
              <Text variant='body2'>
                {confirmingPaymentIntent ? 'Confirming Order... Please do not leave this page' : 'Loading Summary'}
              </Text>
            </div>
            <Spinner size='small' color='primary' />
          </div>
        ) : (
          <div style={{ paddingTop: 4, paddingBottom: 8 }}>
            <div style={{ marginBottom: 6 }}>
              <PurchaseSummary
                subtotal={priceTagData?.priceTag.subtotal || 0}
                tax={priceTagData?.priceTag.tax || 0}
                discount={priceTagData?.priceTag.discount || 0}
              />
            </div>

            <div style={{ marginBottom: 12 }}>
              <Button
                theme='action'
                onClick={() => confirmCardPayment()}
                size='medium'
                label='Place Order'
                disabled={confirmingPaymentIntent || loadingPriceTag}
              />
            </div>

            <div style={{ marginBottom: 12 }}>
              <Button
                theme='cancel'
                onClick={() => onCancel()}
                size='medium'
                label='Cancel'
                disabled={confirmingPaymentIntent || loadingPriceTag}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
